import React, {FunctionComponent} from 'react';
import Layout from '../components/layout';
import {BlogFooter, BlogHeader} from '@focusrite-novation/ampify-web-ui/';
import {FooterContent} from '../types/FooterContent';
import {GutterMaxWidth, Gutters} from '../components/Spacing/Gutters';
import {graphql, PageProps} from 'gatsby';
import Img from 'gatsby-image';
import {renderPageContent} from '../utils/rich-text-renderer';
import {SingleContentfulCollectiveBlogPost} from '../types/BlogHubContent';
import SEO from '../components/seo';
import Theme from '../theme';
import styled from 'styled-components';

interface BlogPostProps extends PageProps<SingleContentfulCollectiveBlogPost> {
  pageContext: {
    contentfulFooterContent: FooterContent;
    customerPortalBaseUrl: string;
    slug: string;
    id: string;
  };
}

const BlogPost: FunctionComponent<BlogPostProps> = ({pageContext, data}) => {
  const {contentfulFooterContent, customerPortalBaseUrl} = pageContext;

  const {pageTitle, updatedAt, headerImage, pageContent} =
    data.contentfulCollectiveBlogPost;
  const url = typeof window !== 'undefined' ? window.location.href : '';
  return (
    <Layout
      {...contentfulFooterContent}
      customerPortalBaseUrl={customerPortalBaseUrl}
    >
      <SEO title={pageTitle} />
      <Gutters
        maxWidth={GutterMaxWidth.LARGE}
        padding={true}
        paddingSides={true}
      >
        <BlogHeader
          title={pageTitle}
          publishDate={new Date(updatedAt)}
          publishDateColor={Theme.colours.orange}
          image={() => (
            <Img
              fluid={headerImage.fluid}
              alt={headerImage.title}
              style={{width: '100%', maxHeight: '500px'}}
            />
          )}
        />
        <Gutters maxWidth={GutterMaxWidth.NORMAL} paddingSides={true}>
          <main>{renderPageContent(pageContent)}</main>
        </Gutters>
        <BlogFooter
          shareUrl={url}
          shareButtonBackgroundColour={Theme.colours.lightGrey}
        />
      </Gutters>
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query WholeArticle($id: String) {
    contentfulCollectiveBlogPost(id: {eq: $id}) {
      id
      slug
      metaLinkLabel
      metaKeywords
      metaDescription
      updatedAt
      pageTitle

      headerImage {
        title
        fluid(maxWidth: 2000, quality: 80) {
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          aspectRatio
        }
      }
      pageContent {
        raw
        references {
          ... on ContentfulAsset {
            id
            contentful_id
            fluid(maxWidth: 2000, quality: 80) {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
            title
            description
          }
          ... on ContentfulQuote {
            id
            contentful_id
            quote
            author
          }
          ... on ContentfulEmbeddableUrl {
            id
            contentful_id
            url: embeddableUrl
            title
          }
          ... on ContentfulFastCollectionCard {
            id
            contentful_id
            pluginDescription
            pluginImage {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
                tracedSVG
              }
              description
              title
            }
            pluginName
            pluginUrl
          }
        }
      }
    }
  }
`;
